import React, { useEffect, useState } from 'react';
import SlimHeader from '../components/SlimHeader';
import MainMenuHeader from '../components/MainMenuHeader';
import MainFooter from '../components/MainFooter';
import { Helmet } from 'react-helmet';

const ProgrammaCorso6View = (props) => {
  const { mediaQuery } = props.gData;

  const [currentDomain, setCurrentDomain] = useState();

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const currentDomain = currentUrl.origin;
    setCurrentDomain(currentDomain);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>HACCP | Corsi haccp online con attestato riconosciuto</title>
        <meta
          name="description"
          content="Ottieni l'attestato haccp ex libretto sanitario con i corsi di alimentarista validi a norma di legge perché riconosciuti a livello europeo come da D.Lgs."
        />
      </Helmet>

      {mediaQuery.isDesktopOrLaptop && <SlimHeader />}
      <MainMenuHeader currentDomain={currentDomain} />

      <div className="container-fluid p-0 bg-white">
        <div className="container mx-auto py-9 px-6 p-0 border-bottom text-primaryLabMar text-md">
          <div className="w-full mb-4 font-bold text-left text-3xl">Il Corso</div>
          <p>
            Il corso è rivolto al personale che deve rinnovare l'attestato. Il corso ha una durata di 6 ore, il tempo
            minimo di studio previsto è di 6 ore, esso partirà al momento dell'iscrizione al corso prescelto.
          </p>
          <div className="w-full mb-4 mt-6 font-bold text-left text-3xl">Programma</div>

          <ul className="w-full my-4 list-disc list-inside">
            <li>microrganismi patogeni e condizioni che favoriscono o inibiscono la relativa crescita;</li>
            <li>
              corretta conservazione degli alimenti e trattamenti di abbattimento microbico, con particolare riferimento
              alla catena del freddo e alle corrette temperature di conservazione degli alimenti;
            </li>
            <li>prevenzione delle tossinfezioni alimentari;</li>
            <li>
              nozioni di base sugli alimenti contenenti glutine, allergeni, relativa normativa e MOCA (materiali a
              contatto degli alimenti)
            </li>
            <li>piani di autocontrollo basati sul sistema HACCP.</li>
          </ul>
        </div>
      </div>

      <MainFooter />
    </>
  );
};

export default ProgrammaCorso6View;
