import React, { useEffect, useState } from 'react';
import { add2Cart } from '../libs/utils';
//import toast from 'react-hot-toast';

const MainStartNowMini = (prop) => {
  const [courses, setCourses] = useState([]);

  const loadRemote = () => {
    try {
      fetch(`${process.env.REACT_APP_API_V1_URL}/trainingOffer/courses`)
        .then((response) => response.json())
        .then((data) => {
          const courses = data?.payload?.courses || [];

          Promise.all(courses.map((course) => getPriceByCourseCode(course.code)))
            .then((prices) => {
              const updatedCourses = courses.map((course, index) => {
                course.price = prices[index];
                return course;
              });
              setCourses(updatedCourses);
            })
            .catch((error) => {
              console.error('Errore nel recuperare il prezzo:', error);
            });
        })
        .catch((error) => {
          console.error('Errore nel recuperare i corsi:', error);
        });
    } catch (error) {
      console.error('Error:', error);
      // toast.error("This didn't work.")
    }
  };

  const getPriceByCourseCode = (courseCode = '') => {
    return new Promise((resolve, reject) => {
      const currentDomain = window.location.origin.replace(/^https?:\/\//, '');
      const externalEndpoint = process.env.REACT_APP_API_V1_URL + '/dashboard/pricelist/';
      const urlWithDomainParam = `${externalEndpoint}?domain=${encodeURIComponent(currentDomain)}`;

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(urlWithDomainParam, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Errore nella richiesta Fetch');
          }
          return response.json();
        })
        .then((data) => {
          const priceList = (data?.payload?.data || []).filter((item) => item.courseCode === courseCode);

          let price = 0;
          let iva = 0;

          if (priceList[0]?.price) {
            price = parseFloat(priceList[0]?.price) > 0 ? parseFloat(priceList[0]?.price) : 0;
          } else {
            price = parseFloat(priceList[0]?.priceCourse) > 0 ? parseFloat(priceList[0]?.priceCourse) : 0;
          }

          iva = 0; //(price * parseFloat(priceList[0]?.iva)) / 100;
          price = parseFloat(price + iva).toFixed(2);

          resolve(price);
        })
        .catch((error) => {
          console.error('Si è verificato un errore:', error);
          reject(error);
        });
    });
  };

  useEffect(() => {
    loadRemote();
  }, []); //useEffect

  useEffect(() => {
    //eslint-disable-next-line
  }, [courses]); //useEffect

  //--- ---
  return (
    <>
      <section id="iniziasubito">
        <div className="container-fluid p-0 bg-primaryLabMar">
          <div className="container mx-auto py-4 sm:py-9 px-6 p-0 border-bottom text-white">
            <div className="w-full mb-6 font-bold text-center text-2xl sm:text-4xl">Inizia subito, scegli il tuo corso</div>

            <ul className="w-full mx-auto grid sm:grid-cols-2 gap-4 text-primaryLabMar">
              {courses.map((course, index) => {
                return (
                  <li key={index} className="shadow-lg text-center p-6 py-7 bg-white rounded-xl">
                    {/*<div className="text-xl mb-1 font-normal text-left">{course.title}</div>*/}
                    <p className="text-xl font-bold text-center">{course.description}</p>
                    <p className="text-4xl font-semibold text-center">€ {parseFloat(course.price).toFixed(2)}</p>

                    {!sessionStorage.getItem('token') ? (
                      <div className="flex flex-row justify-start gap-5">
                        <a
                          href={`/registrati?courseCode=${course.code}&domain=${prop.currentDomain}`}
                          className="mt-4 w-full px-2 py-2 rounded-full font-semibold text-md bg-white text-primaryLabMar border-2 border-primaryLabMar transition-colors duration-300 hover:bg-primaryLabMar hover:text-white hover:border-transparent"
                        >
                          REGISTRATI
                        </a>
                      </div>
                    ) : (
                      <div className="my-6 flex flex-col gap-0">
                        <div className="flex w-full gap-5">
                          <button
                            onClick={() => {
                              add2Cart(course.code, prop.currentDomain, sessionStorage.getItem('token'));
                            }}
                            className="mt-6 w-full px-6 py-3 rounded-full text-center font-semibold text-md bg-white text-primaryLabMar border-2 border-primaryLabMar transition-colors duration-300 hover:bg-primaryLabMar hover:text-white hover:border-transparent"
                          >
                            AGGIUNGI AI TUOI ORDINI
                          </button>
                        </div>
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainStartNowMini;
